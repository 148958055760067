import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import milan_logo from '../milan_logo.png';
import { MenuItem, RadioGroup, Radio, FormControlLabel, FormLabel, FormGroup, FormControl, FormHelperText, Button, InputAdornment, CircularProgress, Checkbox } from '@material-ui/core';
import DateTimePicker from 'react-datetime-picker';
import axios from 'axios';




function EventRequest() {
    const [password, setPassword] = useState('');

    const [eventData, setEventData] = useState({name:'', nameOfEvent: '', eventLocation: '', noOfTeam:'', radioSpecial:'50%', additional_notes:'', drawingPrize: '', expectedAttendance:'', actualAttendance:'', radioContacted:'Yes', oppsWon:'', salesClosed:'', radioConverted:'Yes', radioSalesforce:'Yes', eventGo:'', downFalls:'', successes:'', eventHostAgain:'', hostAgainMaterials:'', suggestions:'' });
    const [subitError, setSubmitError] = useState(false); 
    const [eventStartDate, onStartChange] = useState(new Date());
   
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false); 

    const [materials, setMaterials] = useState({brochures:false, transgenderBrochures:false, priceCards:false, standUpBanner:false, tableTopBanner:false, pens:false, entryForms:false, scratchMatch:false, peelReveal:false, drawingBox:false, pcosBrochures:false, reviewUsCards:false, stressBalls:false});
    const [materialsOut, setMaterialsOut] = useState({brochures:false, transgenderBrochures:false, priceCards:false, standUpBanner:false, tableTopBanner:false, pens:false, entryForms:false, scratchMatch:false, peelReveal:false, drawingBox:false, pcosBrochures:false, reviewUsCards:false, stressBalls:false});

  
    const handleCheck1 = (e) => {
    setMaterials({...materials, [e.target.name]: !materials[e.target.name]  })
    }
    const handleCheck2 = (e) =>{
    setMaterialsOut({...materialsOut, [e.target.name]: !materialsOut[e.target.name] })
    }

   
    const handleChange = (e) => {
        setEventData({...eventData, [e.target.name]: e.target.value})
    }

   const handleSubmit = () => {
if(eventData.name ==='' || eventData.email===''||  eventData.eventLocation=== ""|| eventData.drawingPrize===''|| eventData.expectedAttendance===''|| eventData.actualAttendance==="" || eventData.oppsWon ==="" || eventData.salesClosed==="" || eventData.eventGo === '' || eventData.downFalls=== '' || eventData.successes===''||eventData.eventHostAgain===''||eventData.hostAgainMaterials===''|| eventData.suggestions===""){
    setSubmitError(true);
}
else{
    setLoading(true);
    console.log(eventData);
    let matersOut = '';
    let maters = '';
    for (var key of Object.keys(materials)) {
     if(materials[key]){
        maters = maters +', '+ key;
     }
  }
  for (var key of Object.keys(materialsOut)) {
   if(materialsOut[key]){
      matersOut = matersOut+', ' + key;
   }
}

console.log(maters);
console.log(matersOut);
   
    let form_data = {
        name:eventData.name, 
        event_name: eventData.nameOfEvent,
        date_now: new Date(),
        event_date: eventStartDate,
        follow_up: eventData.radioAvailability,
        event_location: eventData.eventLocation,
        materials: maters,
        ran_out: matersOut,
        event_special: eventData.radioSpecial,
        event_attendance: eventData.expectedAttendance,
        event_showed_up: eventData.actualAttendance,
        opps_closed: eventData.oppsWon,
        sales_won: eventData.salesClosed,
        contact_leads: eventData.radioContacted,
        leads_convert: eventData.radioConverted,
        leads_salesforce: eventData.radioSalesforce,
        drawing: eventData.drawingPrize,
        event_overall: eventData.eventGo,
        event_downfalls: eventData.downFalls,
        event_successes: eventData.successes,
        materials_available: eventData.hostAgainMaterials,
        suggestions: eventData.suggestions,
        event_again: eventData.eventHostAgain
      }
    axios.post("https://milanlaser.com/eventrequest/followup.php",{form_data}, {headers: {
        'X-Requested-With': 'XMLHttpRequest'
    }}).then(res=>{
        console.log(res);
        setSuccess(true);
    })
}
   }

    return (
        <Grid container style={{ alignItems: 'center', flexDirection: 'column' }}>
            <img alt="logo" src={milan_logo} width={200} />
            <Typography variant="h4">Event Follow Up Form</Typography>
            {password === "7157" ?
               loading ? success? <><Typography style={{marginTop:'5%'}} variant="h6">Thank You! Your Follow up form has been submitted.</Typography></> : <div style={{marginTop:'5%'}}><CircularProgress /></div> :
                <Grid container style={{ margin: "2%", alignItems: 'center', justifyContent: 'center' }}>
                    <TextField label="Your Name" name="name" value={eventData.name} onChange={handleChange} variant="outlined" style={{ margin: 15, width: '35%' }}>
                    </TextField>
                    <TextField label="Name of the Event" name="nameOfEvent" value={eventData.nameOfEvent} onChange={handleChange} variant="outlined" style={{ margin: 15, width: '35%' }}>
                    </TextField>
                    
                    <div style={{ width: '35%', margin: 15 }}>
                    <FormHelperText  className="datePickerLabel">Event Date </FormHelperText>
                        <DateTimePicker tabIndex="-1"
                            onChange={onStartChange}
                            value={eventStartDate}
                        />
                    </div>
                    <TextField label="Event Location" name="eventLocation" value={eventData.eventLocation} onChange={handleChange} variant="outlined" style={{ margin: 15, width: '35%' }}>
                    </TextField>
                    <div style={{ width: '35%', margin: 15, textAlign:'left' }}>           
                    <FormControl component="fieldset" >
        <FormLabel component="legend">What materials did you have at your event?</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox color="primary" checked={materials.brochures} onChange={handleCheck1} name="brochures" />}
            label="Brochures"
          />
          <FormControlLabel
            control={<Checkbox color="primary" checked={materials.transgenderBrochures} onChange={handleCheck1} name="transgenderBrochures" />}
            label="Transgender Brochures"
          />
          <FormControlLabel
            control={<Checkbox color="primary" checked={materials.priceCards} onChange={handleCheck1} name="priceCards" />}
            label="Price Cards"
          />
          <FormControlLabel
            control={<Checkbox color="primary" checked={materials.standUpBanner} onChange={handleCheck1} name="standUpBanner" />}
            label="Stand-up Banner"
          />
          <FormControlLabel
            control={<Checkbox color="primary" checked={materials.tableTopBanner} onChange={handleCheck1} name="tableTopBanner" />}
            label="Tabletop Banner"
          />
          <FormControlLabel
            control={<Checkbox color="primary" checked={materials.pens} onChange={handleCheck1} name="pens" />}
            label="Pens"
          />
          <FormControlLabel
            control={<Checkbox color="primary" checked={materials.entryForms} onChange={handleCheck1} name="entryForms" />}
            label="Entry Forms"
          />
          <FormControlLabel
            control={<Checkbox color="primary" checked={materials.scratchMatch} onChange={handleCheck1} name="scratchMatch" />}
            label="Scratch & Match"
          />
          <FormControlLabel
            control={<Checkbox color="primary" checked={materials.peelReveal} onChange={handleCheck1} name="peelReveal" />}
            label="Peel & Reveal"
          />
          <FormControlLabel
            control={<Checkbox color="primary" checked={materials.drawingBox} onChange={handleCheck1} name="drawingBox" />}
            label="Drawing Box"
          />
          <FormControlLabel
            control={<Checkbox color="primary" checked={materials.pcosBrochures} onChange={handleCheck1} name="pcosBrochures" />}
            label="PCOS Brochures"
          />
          <FormControlLabel
            control={<Checkbox color="primary" checked={materials.reviewUsCards} onChange={handleCheck1} name="reviewUsCards" />}
            label="Review Us Cards"
          />
           <FormControlLabel
            control={<Checkbox color="primary" checked={materials.stressBalls} onChange={handleCheck1} name="stressBalls" />}
            label="Stress Balls"
          />
        </FormGroup>
      </FormControl>
      </div>
      <div style={{ width: '35%', margin: 15, textAlign:'left' }}>
      <FormControl component="fieldset" >
        <FormLabel component="legend">Materials you ran out of at your event?</FormLabel>
        <FormGroup>
        <FormControlLabel
            control={<Checkbox color="primary" checked={materialsOut.brochures} onChange={handleCheck2} name="brochures" />}
            label="Brochures"
          />
          <FormControlLabel
            control={<Checkbox color="primary" checked={materialsOut.transgenderBrochures} onChange={handleCheck2} name="transgenderBrochures" />}
            label="Transgender Brochures"
          />
          <FormControlLabel
            control={<Checkbox color="primary" checked={materialsOut.priceCards} onChange={handleCheck2} name="priceCards" />}
            label="Price Cards"
          />
          <FormControlLabel
            control={<Checkbox color="primary" checked={materialsOut.standUpBanner} onChange={handleCheck2} name="standUpBanner" />}
            label="Stand-up Banner"
          />
          <FormControlLabel
            control={<Checkbox color="primary" checked={materialsOut.tableTopBanner} onChange={handleCheck2} name="tableTopBanner" />}
            label="Tabletop Banner"
          />
          <FormControlLabel
            control={<Checkbox color="primary" checked={materialsOut.pens} onChange={handleCheck2} name="pens" />}
            label="Pens"
          />
          <FormControlLabel
            control={<Checkbox color="primary" checked={materialsOut.entryForms} onChange={handleCheck2} name="entryForms" />}
            label="Entry Forms"
          />
          <FormControlLabel
            control={<Checkbox color="primary" checked={materialsOut.scratchMatch} onChange={handleCheck2} name="scratchMatch" />}
            label="Scratch & Match"
          />
          <FormControlLabel
            control={<Checkbox color="primary" checked={materialsOut.peelReveal} onChange={handleCheck2} name="peelReveal" />}
            label="Peel & Reveal"
          />
          <FormControlLabel
            control={<Checkbox color="primary" checked={materialsOut.drawingBox} onChange={handleCheck2} name="drawingBox" />}
            label="Drawing Box"
          />
          <FormControlLabel
            control={<Checkbox color="primary" checked={materialsOut.pcosBrochures} onChange={handleCheck2} name="pcosBrochures" />}
            label="PCOS Brochures"
          />
          <FormControlLabel
            control={<Checkbox color="primary" checked={materialsOut.reviewUsCards} onChange={handleCheck2} name="reviewUsCards" />}
            label="Review Us Cards"
          />
          <FormControlLabel
            control={<Checkbox color="primary" checked={materialsOut.stressBalls} onChange={handleCheck2} name="stressBalls" />}
            label="Stress Balls"
          />
        </FormGroup>
      </FormControl>
      </div>
                    <div style={{ width: '35%', margin: 15, textAlign:'left' }}>
                        <FormControl component="fieldset" style={{marginLeft:15}} >
                            <FormLabel component="legend">What is the Special offered at this event/party? </FormLabel>
                            <RadioGroup aria-label="gender" name="radioSpecial" value={eventData.radioSpecial} onChange={handleChange}>
                                <FormControlLabel value="50%" control={<Radio color="primary" />} label="50%" />
                                <FormControlLabel value="60%" control={<Radio color="primary" />} label="60%" />
                                <FormControlLabel value="Peel &amp; Reveal (30%, 40% &amp; 50% Off)" control={<Radio color="primary" />} label="Peel &amp; Reveal (30%, 40% &amp; 50% Off)
" />
                                <FormControlLabel value="Scratch &amp; Match (30%, 40% &amp; 50% Off)" control={<Radio color="primary" />} label="Scratch &amp; Match (30%, 40% &amp; 50% Off)" />
                            </RadioGroup>
                        </FormControl>
                    </div>

                    <TextField label="Drawing Prize Offered?" name="drawingPrize" value={eventData.drawingPrize} onChange={handleChange} variant="outlined" style={{ margin: 15, width: '35%' }}>
                    </TextField>

                    <TextField label="Expected Attendance/ How many people RSVP'd?" name="expectedAttendance" value={eventData.expectedAttendance} onChange={handleChange} variant="outlined" style={{ margin: 15, width: '35%' }}>
                    </TextField>
                    <TextField label="How many People showed up to the Event/ Party?" name="actualAttendance" value={eventData.actualAttendance} onChange={handleChange} variant="outlined" style={{ margin: 15, width: '35%' }}>
                    </TextField>
                    <TextField label="How many opportunities were Closed and Won at the Event?" name="oppsWon" value={eventData.oppsWon} onChange={handleChange} variant="outlined" style={{ margin: 15, width: '35%' }}>
                    </TextField>
                    <TextField label="How much in Sales was Closed and Won at the Event?" variant="outlined" name="salesClosed" style={{ margin: 15, width: '35%' }} onChange={handleChange} value={eventData.salesClosed}  InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>
          }}>
                    </TextField>

                    <div style={{ width: '35%', margin: 15, textAlign:'left' }}>
                        <FormControl component="fieldset" style={{marginLeft:15}}  >
                            <FormLabel component="label">Have you contacted any of your leads? (i.e. Email, Phone Call, Text Follow-up)</FormLabel>
                            <RadioGroup aria-label="gender" name="radioContacted" value={eventData.radioContacted} onChange={handleChange}>
                    <FormControlLabel value="Yes" control={<Radio color="primary" />} label="Yes" />
                <FormControlLabel value="No" control={<Radio color="primary" />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </div>

                    <div style={{ width: '35%', margin: 15, textAlign:'left' }}>
                        <FormControl component="fieldset" style={{marginLeft:15}}  >
                            <FormLabel component="label">If so, have any of your leads have been converted to clients and scheduled for appointment? </FormLabel>
                            <RadioGroup aria-label="gender" name="radioConverted" value={eventData.radioConverted} onChange={handleChange}>
                    <FormControlLabel value="Yes" control={<Radio color="primary" />} label="Yes" />
                <FormControlLabel value="No" control={<Radio color="primary" />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </div>

                    <div style={{ width: '35%', margin: 15, textAlign:'left' }}>
                        <FormControl component="fieldset" style={{marginLeft:15}}  >
                            <FormLabel component="label">Have all your leads been added to Salesforce and attached to their campaign? </FormLabel>
                            <RadioGroup aria-label="gender" name="radioSalesforce" value={eventData.radioSalesforce} onChange={handleChange}>
                    <FormControlLabel value="Yes" control={<Radio color="primary" />} label="Yes" />
                <FormControlLabel value="No" control={<Radio color="primary" />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </div>

                    <TextField label="Overall, how did the event go?" multiline name="eventGo" value={eventData.eventGo} onChange={handleChange} variant="outlined" style={{ margin: 15, width: '35%' }}>
                    </TextField>

                    <TextField label="What were some downfalls in this event?" multiline name="downFalls" value={eventData.downFalls} onChange={handleChange} variant="outlined" style={{ margin: 15, width: '35%' }}>
                    </TextField>
                    <TextField label="What were some successes of this event?" multiline name="successes" value={eventData.successes} onChange={handleChange} variant="outlined" style={{ margin: 15, width: '35%' }}>
                    </TextField>

                    <TextField label="Would you attend/host this event again? Why or why not?" multiline name="eventHostAgain" value={eventData.eventHostAgain} onChange={handleChange} variant="outlined" style={{ margin: 15, width: '70%' }}>
                    </TextField>

                    <TextField label="If you do this event again, what materials would you like to see available?"  name="hostAgainMaterials" value={eventData.hostAgainMaterials} onChange={handleChange} variant="outlined" style={{ margin: 15, width: '70%' }}>
                    </TextField>
                    <div style={{width:'100%'}}>
                    <TextField label="Any suggestions for future events?" multiline name="suggestions" value={eventData.suggestions} onChange={handleChange} variant="outlined" style={{ margin: 15, width: '70%' }}>
                    </TextField>
                    </div>
                    
        {subitError && <div style={{width:'100%', textAlign:'center', margin:15}}><Typography style={{color:'red'}}>Please fill all the required fields</Typography></div> }
                    <Button variant="contained" onClick={handleSubmit} className="submitButton" color="primary">Submit Your Request</Button>
                </Grid> :
                <TextField value={password} onChange={(e) => { setPassword(e.target.value) }} variant="outlined" style={{ marginTop: 25 }} label="Enter the Password"
                    error={password.length > 3 && password !== '7157'} helperText={(password.length > 3 && password !== '7157') && 'Incorrect Password'} />
            }
        </Grid>
    )
}

export default EventRequest
